import React, {useCallback, useEffect, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import { global, short,input, sip, weekDays, ivr} from "../translate";
import {useDispatch, useSelector} from "react-redux";
import {ivrAdd, ivrCreateRequest} from "../store/actions/ivr";
import {fileRequest} from "../store/actions/file";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "query-string";
import 'flatpickr/dist/themes/material_blue.css';
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import Cookies from "js-cookie";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 820 ? 820 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: '97vh',
    height: 'auto',
    overflow: 'auto',
};
const customStyles = {
    menu: (provided) => ({
        ...provided,
        position: 'absolute',
        zIndex: 9999,
        maxHeight: 300,
    }),
    menuPortal: (base) => ({...base, zIndex: 9999}), // Ensures portal menu is above modal
};
function AddIvr({open, setOpen}) {
    const [form, setForm] = useState({
        ivr: '',
        private_num: '',
        no_answer: '',
        time: []
    })
    const fileData = useSelector(state => state.file.fileData)
    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const [graphic, setGraphic] = useState({
        num: '',
        notes: '',
        weekStart: weekDays[1][+localStorage.getItem('atsLang') || 1],
        weekEnd: weekDays[0][+localStorage.getItem('atsLang') || 1],
        timeStart: '00:00',
        timeEnd: '23:59',
        rx: 0,
        tx: 0,
    })

    const dispatch = useDispatch()

    const handleChange = useCallback((name, ev) => {
        setForm((prevState) => ({
            ...prevState,
            [name]: ev
        }))
    }, [])

    const handleTimeChange = useCallback((name, ev,notes) => {
        if(name === 'num'){
            setGraphic((prevState) => ({
                ...prevState,
                [name]: ev,
                notes: notes || ''
            }))
        } else {
            setGraphic((prevState) => ({
                ...prevState,
                [name]: ev
            }))
        }
    }, [])
    const handleAdd = useCallback(async (ev) => {
        ev.preventDefault()
        await dispatch(ivrCreateRequest(Cookies.get("ivr") ||query.limit || 10,query.page||1,query.search || '', form))
        setOpen(false)
    }, [form,location.search])
    const handleAddGraphic = useCallback(async () => {
        setForm((prev) => ({
            ...prev,
            time: [...prev.time, {...graphic,id:new Date()}]
        }))
    }, [graphic])
    const handleDelete = useCallback(async (id) => {
        setForm((prev) => ({
            ...prev,
            time: prev.time.filter(t => t.id !== id)
        }))
    }, [graphic])
    useEffect(() => {
        (async () => {
            await dispatch(fileRequest())
        })()
    },[])
    const handleTime = (name, value) => {
        const input = value;
        if (/^([0-9]{0,2}):?([0-9]{0,2})?$/.test(input) || input === '') {
            setGraphic((prevState) => ({
                ...prevState,
                [name]: input,
            }))
        }
    };

    const handleBlur = (name) => {
        if (graphic[name]) {
            const [hours, minutes] = graphic[name].split(':').map(Number);
            const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
            setGraphic((prevState) => ({
                ...prevState,
                [name]: formattedTime,
            }))
        }
    };
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {ivr.ivr[+localStorage.getItem('atsLang')  || 1 ]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                             <CloseIcon/>
                        </span>
                    </div>
                    <form onSubmit={handleAdd}>
                        <div className="row" style={{margin: '30px 0'}}>
                            <label style={{margin:'20px 0'}} className="col-md-6 count">
                            <span>
                               {ivr.ivr[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                                <input value={form.ivr} onChange={(ev) => handleChange('ivr', ev.target.value)}/>
                            </label>
                            <label style={{margin:'20px 0'}} className="col-md-6 count">
                            <span>
                               {ivr.private[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                                <input
                                    type="number"
                                    value={form.private_num}
                                    onChange={(ev) => handleChange('private_num', ev.target.value)}/>
                            </label>
                            <label style={{margin:'20px 0'}} className="col-md-12 count">
                            <span>
                               {short.no_answer[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                                <input
                                    type="number"
                                    value={form.no_answer}
                                    onChange={(ev) => handleChange('no_answer', ev.target.value)}/>
                            </label>
                        </div>
                        <div className="short_block" style={{margin: '30px 0',}}>
                            <div className="d-flex flex-row text-center" style={{width: 'fit-content'}}>
                                <div style={{width: 150}}>
                                    {ivr.file[+localStorage.getItem('atsLang')  || 1 ]}
                                </div>
                                <div style={{width: 50,margin: '0 10px'}}>
                                    RX
                                </div>
                                <div style={{width: 50,margin: '0 10px'}}>
                                    TX
                                </div>
                                <div style={{width: 150}}>
                                    {short.week[+localStorage.getItem('atsLang')  || 1 ]}
                                </div>
                                <div style={{width: 150}}>
                                    {short.time[+localStorage.getItem('atsLang')  || 1 ]}
                                </div>
                                <div style={{width: 150}}>
                                    {sip.action[+localStorage.getItem('atsLang')  || 1 ]}
                                </div>
                            </div>
                            {form.time.map(s => (
                                <div key={s.id} className="d-flex flex-row text-center" style={{width: 'fit-content',margin:'10px 0'}}>
                                    <div style={{width: 150}}>
                                   <span>
                                       {s.notes}
                                   </span>
                                    </div>
                                    <div style={{width: 50,margin: '0 10px'}}>
                                   <span>
                                       {s.rx}
                                   </span>
                                    </div>
                                    <div style={{width: 50,margin: '0 10px'}}>
                                   <span>
                                       {s.tx}
                                   </span>
                                    </div>
                                    <div style={{width: 150}}>
                                    <span style={{width: '40%', height: 40,display:'inline-block'}}>
                                       {s.weekStart}
                                   </span>
                                        <span>-</span>
                                        <span style={{width: '40%', height: 40,display:'inline-block'}}>
                                        {s.weekEnd}
                                    </span>
                                    </div>
                                    <div style={{width: 150}}>
                                    <span style={{width: '40%', height: 40}} >
                                        {s.timeStart}
                                    </span>
                                        <span>-</span>
                                        <span style={{width: '40%', height: 40}} >
                                        {s.timeEnd}
                                    </span>
                                    </div>
                                    <div style={{width: 150}} className="d-flex justify-content-center">
                                        <span onClick={() => handleDelete(s.id)} className="deleteBtn" style={{background:'red'}}>
                                            {global.delete[+localStorage.getItem('atsLang')  || 1 ]}
                                        </span>
                                    </div>

                                </div>
                            ))}
                            <div className="d-flex flex-row text-center" style={{width: 'fit-content',margin:'10px 0'}}>

                                <div style={{width: 150}}>

                                    <Select
                                        styles={customStyles}
                                        menuPlacement="auto"
                                        menuPosition="fixed"
                                        onChange={(ev) => handleTimeChange('num', ev.value.split('*')[1], ev.value.split('*')[0])}
                                        menuPortalTarget={document.body}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isSearchable={true}
                                        defaultValue={graphic.num + '*' + graphic?.notes}
                                        name="color"
                                        options={fileData.map(k => (
                                            {value:k.name + '*' + k.fname, label: `${k.name} ( ${k.fname} )`}
                                        ))}
                                    />
                                </div>
                                <div className="count" style={{width: 50,margin: '0 10px',textAlign:"center"}}>
                                    <input type="number" style={{textAlign:"center"}} value={graphic.rx} onChange={(ev) => handleTimeChange('rx',ev.target.value)}/>
                                </div>
                                <div className="count" style={{width: 50,margin: '0 10px',textAlign:"center"}}>
                                    <input type="number" style={{textAlign:"center"}} value={graphic.tx} onChange={(ev) => handleTimeChange('tx',ev.target.value)}/>
                                </div>
                                <div style={{width: 150}}>
                                    <select value={graphic.weekStart} onChange={(ev) => handleTimeChange('weekStart',ev.target.value)} style={{width: '40%',border: '1px solid',textAlign:"center", height: 40}}>
                                        <option>{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                        {weekDays.map(k => (
                                            <option key={k[0]} value={k[+localStorage.getItem('atsLang')  || 1 ]}>{k[+localStorage.getItem('atsLang')  || 1 ]}</option>
                                        ))}
                                    </select>
                                    <span>-</span>
                                    <select value={graphic.weekEnd} onChange={(ev) => handleTimeChange('weekEnd',ev.target.value)} style={{width: '40%',border: '1px solid',textAlign:"center", height: 40}}>
                                        <option>{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                        {weekDays.map(k => (
                                            <option key={k[0]} value={k[+localStorage.getItem('atsLang')  || 1 ]}>{k[+localStorage.getItem('atsLang')  || 1 ]}</option>
                                        ))}
                                    </select>
                                </div>
                                <div style={{width: 150}}>
                                    <input
                                        type="text"
                                        value={graphic.timeStart}
                                        onChange={(ev) => handleTime('timeStart',ev.target.value)}
                                        onBlur={() => handleBlur('timeStart')}
                                        placeholder="HH:mm"
                                        style={{
                                            width: '40%',
                                            textAlign: 'center',
                                            border: '1px solid',
                                            height: 35,
                                            fontSize: 14
                                        }}
                                    />
                                    <span>-</span>
                                    <input
                                        type="text"
                                        value={graphic.timeEnd}
                                        onChange={(ev) => handleTime('timeEnd',ev.target.value)}
                                        onBlur={() => handleBlur('timeEnd')}
                                        placeholder="HH:mm"
                                        style={{
                                            width: '40%',
                                            textAlign: 'center',
                                            border: '1px solid',
                                            height: 35,
                                            fontSize: 14
                                        }}
                                    />
                                </div>
                                <div style={{width: 150}} className="d-flex justify-content-center">
                                    <span onClick={handleAddGraphic} className="addBtn">
                                        {sip.add_btn[+localStorage.getItem('atsLang')  || 1 ]}
                                    </span>
                                </div>

                            </div>
                        </div>

                        <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                            <button onClick={handleAdd} className="addBtnBig">
                                {sip.add_btn[+localStorage.getItem('atsLang')  || 1 ]}
                            </button>
                        </div>
                    </form>
                </Box>
            </Fade>
        </Modal>
    );
}

export default AddIvr;
