import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import {file, global, menu} from "../translate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {toast} from "react-toastify";
import TextToVoice from "../components/TextToVoice";
import Yandex from "../components/Yandex";
import Cookies from "js-cookie";
import Api from "../Api";
import {useDispatch, useSelector} from "react-redux";
import {display} from "@mui/system";
import {
    fileCreateRequest,
    fileDeleteLocal,
    fileDeleteRequest,
    fileRequest,
    fileUpdateRequest
} from "../store/actions/file";
import {REACT_APP_API_URL} from '../config';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {Helmet} from "react-helmet";
import {autoCallDeleteLocal, autoCallDeleteRequest} from "../store/actions/autoCall";
const {REACT_APP_SERVER} = process.env;
let color = REACT_APP_SERVER === 'ATS'?'#0973b9':'#7ebc0a'
function Files(props) {
    const [form,setForm] = useState({
        name:'',
        file:{},
        size:0
    })

    const fileData = useSelector(state => state.file.fileData)

    const handleChange = useCallback((name,ev) => {
        setForm((prev) => ({
            ...prev,
            [name]:ev
        }))
    },[])
    const dispatch = useDispatch()
    const handleAdd = useCallback(async (ev) => {
        ev.preventDefault()
        if(!form.name){
            toast.error("Please enter name!")
            return
        }
        await dispatch(fileCreateRequest({...form,size:form.file.size}))
    }, [form])

    const handleDelete = useCallback(async (id) => {
        try {
            let text = +localStorage.getItem('atsLang') === 1?"Are you sure you want to delete?":
                +localStorage.getItem('atsLang') === 2?"Вы уверены, что хотите судалить?":
                    "Վստա՞հ եք, որ ցանկանում եք ջնջել";

            if (confirm(text) === true) {
                await dispatch(fileDeleteLocal(id))
                await dispatch(fileDeleteRequest(id))
            }
        }catch (e) {
            console.log(e)
        }
    },[])
    const handleMusicHold = useCallback(async (id,value) => {
        await dispatch(fileUpdateRequest(id,+value === 1?0:1))
    }, [])
    const handleInstall = useCallback(async (id) => {
        await Api.download(id)
    }, [])
    const [yandex,setYandex] = useState(false)
    const [text,setText] = useState(false)

    useEffect(() => {
        (async () =>{
           await dispatch(fileRequest())
        })()
    }, [])
    return (
        <>
            <Helmet>
                <title>
                    {menu?.file[+localStorage.getItem('atsLang')  || 1 ]}
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent" style={{background: 'white', padding: 35, minWidth:720,}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h3>{file.file[+localStorage.getItem('atsLang')  || 1]}</h3>
                        <button
                            onClick={() => setText(true)}
                            className="addBtnBig"
                            style={{margin: '10px 0'}}>{file.voice[+localStorage.getItem('atsLang')  || 1]}</button>
                        <button
                            onClick={() => setYandex(true)}
                            className="addBtnBig"
                            style={{margin: '10px'}}>{file.yandex[+localStorage.getItem('atsLang')  || 1]}</button>
                    </div>
                    <form onSubmit={handleAdd} className="row" style={{marginTop: '40px'}}>
                        <div className="d-flex ">
                        <span style={{marginRight: '50px'}}>
                            {file.name[+localStorage.getItem('atsLang')  || 1]}
                        </span>
                            <label>
                                <input value={form.name} onChange={(ev) => handleChange('name',ev.target.value)} className="ok" type="text"/>
                            </label>
                            <span style={{padding: '0px 50px 0px 42px'}}>
                            {file.name1[+localStorage.getItem('atsLang')  || 1]}
                        </span>
                        </div>
                        <div style={{marginTop: '40px'}} className="d-flex align-items-center">
                        <span style={{marginRight: '50px'}}>
                            {file.choose[+localStorage.getItem('atsLang')  || 1]}
                        </span>
                            <label className="addBtnBig d-flex align-items-center" style={{
                                background: '#555',
                                marginTop: '10px',
                                width:'150px',
                                height:'60px',
                                marginBottom: '10px',
                                marginRight: '50px',
                            }}>
                                <span className="forFile">{form.file.name?form.file.name:file.choose[+localStorage.getItem('atsLang')  || 1]}</span>
                                <input onChange={(ev) => handleChange('file',ev.target.files[0])} className="ok" style={{display: 'none'}} type="file"/>
                            </label>
                            <span>
                            {file.key[+localStorage.getItem('atsLang')  || 1]}
                        </span>
                        </div>

                        <div style={{marginTop: '40px'}}>
                            <button className="addBtnBig"
                                    onClick={handleAdd}
                                    style={{margin: '10px 0'}}>{global.add[+localStorage.getItem('atsLang')  || 1]}</button>
                        </div>
                    </form>
                </div>
                <div className="tableParent file" style={{background: 'white', minWidth:720,padding: 35}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h3>{file.file[+localStorage.getItem('atsLang')  || 1]}</h3>
                    </div>
                    <table cellPadding="0" cellSpacing="0">
                        <thead>
                        <tr>
                            <th/>
                            <th>{file.name[+localStorage.getItem('atsLang')  || 1]}</th>
                            <th>{file.fileUpload[+localStorage.getItem('atsLang')  || 1]}</th>
                            <th>{file.volume[+localStorage.getItem('atsLang')  || 1]}</th>
                            <th>{file.code[+localStorage.getItem('atsLang')  || 1]}</th>
                            <th>{file.hold[+localStorage.getItem('atsLang')  || 1]}</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        {fileData.length? fileData.map(f => (
                            <tr key={f.fname}>
                                <td><CheckCircleIcon style={{color:color}} /></td>
                                {/*<td><span onClick={() => handleInstall(f.fname)}>{f.name}</span></td>*/}
                                <td><a style={{textDecoration:'revert',color:'blue'}} target="_blank" href={REACT_APP_API_URL+'/files/download?uid='+f.fname } download={f.fname + '.mp3'}>{f.name}</a></td>

                                <td>{new Date(f.date).toLocaleString()}</td>
                                <td>{Math.round(+f.size/(1024 * 1024) * 1000) / 1000} mb</td>
                                <td>{f.fname}</td>
                                <td><input type="radio" value={f.mhold} checked={f.mhold} onClick={(ev) => handleMusicHold(f.id,ev.target.value)}/></td>
                                <td style={{cursor:'pointer',color:'red'}} onClick={() => handleDelete(f.id)}><FontAwesomeIcon icon={faTrashCan}/></td>
                            </tr>
                        )):null}

                        </tbody>

                    </table>
                </div>
                {text?<TextToVoice open={text} setOpen={setText} />:null}
                {yandex?<Yandex open={yandex} setOpen={setYandex} />:null}
            </Wrapper>

        </>
    );
}

export default Files;
