import React, {useCallback, useState} from 'react';
import {historyHeader, input, sip} from "../translate";
import Backdrop from "@mui/material/Backdrop";
import {Box, Fade, Modal, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Api from "../Api";
import {toast} from "react-toastify";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 700 ? 700 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    // height: window.innerWidth > 600?'50vh':'97vh',
    overflow: 'auto'
};

function AmoCall({open, setOpen}) {
    const [obj, setObj] = useState({

        incoming:'',
        outgoing: '',
        missed: '',
    })
    const handleRadioChange = useCallback((name, index, ev) => {
        setObj(prev => {
            return { ...prev, [name]: ev.target.value };
        });
    }, []);

    const handleSubmit = useCallback(async () => {
        for (let openElement of open) {
            await Api.amoCrmConfigs(obj,openElement.sip)
        }
        toast.success("Successfully saved!")
        setOpen(false)
    }, [obj,open])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {sip.add[+localStorage.getItem('atsLang') || 1]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                            <CloseIcon/>
                        </span>
                    </div>
                    <div className="call_settings">
                        <table className="w-100">
                            <tr>
                                <th/>
                                <th>{historyHeader.nothing[+localStorage.getItem('atsLang') || 1]}</th>
                                <th>{historyHeader.contact[+localStorage.getItem('atsLang') || 1]}</th>
                                <th>{historyHeader.contact_lead[+localStorage.getItem('atsLang') || 1]}</th>
                                <th>{historyHeader.unsorted[+localStorage.getItem('atsLang') || 1]}</th>
                            </tr>
                            <tr>
                                <td>{historyHeader.incoming[+localStorage.getItem('atsLang') || 1]}</td>
                                <td><input value="nothing" name="incoming" type="radio"
                                           onClick={(ev) => handleRadioChange('incoming', 0, ev)}
                                           checked={obj.incoming === 'nothing'}/></td>
                                <td><input value="contact" name="incoming" type="radio"
                                    onClick={(ev) => handleRadioChange('incoming', 1, ev)}
                                    checked={obj.incoming === 'contact'}/></td>
                                <td><input value="contact_deal" name="incoming" type="radio"
                                           onClick={(ev) => handleRadioChange('incoming', 2, ev)}
                                           checked={obj.incoming === 'contact_deal'}/></td>
                                <td><input value="none" name="incoming" type="radio"
                                           onClick={(ev) => handleRadioChange('incoming', 3, ev)}
                                           checked={obj.incoming === 'none'}/></td>
                            </tr>
                            <tr>
                                <td>{historyHeader.outgoing[+localStorage.getItem('atsLang') || 1]}</td>
                                <td><input value="nothing" name="outgoing" type="radio"
                                           onClick={(ev) => handleRadioChange('outgoing', 0, ev)}
                                           checked={obj.outgoing=== 'nothing'}/></td>
                                <td><input value="contact" name="outgoing" type="radio"
                                           onClick={(ev) => handleRadioChange('outgoing', 1, ev)}
                                           checked={obj.outgoing=== 'contact'}/></td>
                                <td><input value="contact_deal" name="outgoing" type="radio"
                                           onClick={(ev) => handleRadioChange('outgoing', 2, ev)}
                                           checked={obj.outgoing=== 'contact_deal'}/></td>
                                <td/>
                            </tr>
                            <tr>
                                <td>{historyHeader.missed[+localStorage.getItem('atsLang') || 1]}</td>
                                <td><input value="nothing" name="missed" type="radio"
                                           onClick={(ev) => handleRadioChange('missed', 0, ev)}
                                           checked={obj.missed=== 'nothing'}/></td>
                                <td><input value="contact" name="missed" type="radio"
                                           onClick={(ev) => handleRadioChange('missed', 1, ev)}
                                           checked={obj.missed=== 'contact'}/></td>
                                <td><input value="contact_deal" name="contact" type="radio"
                                           onClick={(ev) => handleRadioChange('missed', 2, ev)}
                                           checked={obj.missed=== 'contact_deal'}/></td>
                                <td><input value="none" name="missed" type="radio"
                                           onClick={(ev) => handleRadioChange('missed', 3, ev)}
                                           checked={obj.missed=== 'none'}/></td>
                            </tr>
                        </table>
                        <hr/>
                        {/*<div className="row">*/}
                        {/*    <label className="col-md-12">*/}
                        {/*        <span className='d-flex align-items-center'>*/}
                        {/*            <input className="call_check" type="checkbox"/>*/}
                        {/*            <span>hello</span>*/}

                        {/*        </span>*/}
                        {/*    </label><label className="col-md-12">*/}
                        {/*        <span className='d-flex align-items-center'>*/}
                        {/*            <input className="call_check" type="checkbox"/>*/}
                        {/*            <span>hello</span>*/}

                        {/*        </span>*/}
                        {/*</label><label className="col-md-12">*/}
                        {/*        <span className='d-flex align-items-center'>*/}
                        {/*            <input className="call_check" type="checkbox"/>*/}
                        {/*            <span>hello</span>*/}

                        {/*        </span>*/}
                        {/*</label><label className="col-md-12">*/}
                        {/*        <span className='d-flex align-items-center'>*/}
                        {/*            <input className="call_check" type="checkbox"/>*/}
                        {/*            <span>hello</span>*/}

                        {/*        </span>*/}
                        {/*</label><label className="col-md-12">*/}
                        {/*        <span className='d-flex align-items-center'>*/}
                        {/*            <input className="call_check" type="checkbox"/>*/}
                        {/*            <span>hello</span>*/}

                        {/*        </span>*/}
                        {/*</label>*/}
                        {/*</div>*/}
                        <hr/>

                        <button onClick={handleSubmit} className="addBtn">
                            Save
                        </button>

                    </div>

                </Box>
            </Fade>
        </Modal>
    );
}

export default AmoCall;
