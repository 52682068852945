import React, {useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import Api from "../Api";
import SpyOperator from "../components/SpyOperator";

function SpyServer(props) {
    const [sip,setSip] = useState([])
    useEffect(() => {
        (async () => {
            const {data} = await Api.apyAdminGet()
            setSip(data.sip)
        })()
    }, [])
    return (
        <Wrapper>
            {!sip.length?<div className="tableParent" style={{background:'white',padding:20}} id="tableParent">
                <h1 className="text-center">Spy Server</h1>
                <p className="text-center">Please select your spy admin</p>
            </div>:null}
            <div className="d-flex flex-wrap">
                {sip.map((item,index) => (<SpyOperator key={index} item={item}/>))}
            </div>
        </Wrapper>
    );
}

export default SpyServer;