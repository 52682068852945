import React, {useCallback, useEffect, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {included, input, sip, short} from "../translate";
import {useDispatch, useSelector} from "react-redux";
import {include, includeCreateRequest, providerRequest} from "../store/actions/included";
import {useLocation, useNavigate} from "react-router-dom";
import qs from "query-string";
import Cookies from "js-cookie";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'auto',
    overflow: 'auto',
    maxHeight: '97vh'
};

function AddInclude({open, setOpen}) {
    const [form, setForm] = useState({
        note: '',
        isMob: Cookies.get('viewer') && Cookies.get('original') ? '' : 42,
        phone: '',
        isShort: false,
        short: ''
    })

    const dispatch = useDispatch()
    const provider = useSelector(state => state.included.provider)
    const location = useLocation()
    const userData = useSelector((state) => state.users.userData)

    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const handleChange = useCallback((name, ev) => {
        console.log(ev)
        if(name === 'isShort' && !ev){
            setForm((prevState) => ({
                ...prevState,
                short: ''
            }))
        }

        setForm((prevState) => ({
            ...prevState,
            [name]: ev
        }))
    }, [])
    const handleWheel = useCallback((event) => {
        event.target.blur();
        event.preventDefault();
    },[]);
    const handleAdd = useCallback(async (ev) => {
        ev.preventDefault()
        await dispatch(includeCreateRequest(Cookies.get("included") || query.limit, query.page, query.search, form, query.sortBy, query.sortOrder))
        setOpen(false)
    }, [form, location.search])
    useEffect(() => {
        (async () => {
            await dispatch(providerRequest())
        })()
    }, [])

    useEffect(() => {
    }, [provider])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {included.private[+localStorage.getItem('atsLang') || 1]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                            <CloseIcon/>
                        </span>
                    </div>
                    <form onSubmit={handleAdd}>
                        <div id="transition-modal-description sip_content" className="row" style={{marginTop: 20}}>
                            <label className="count">
                            <span>
                               {included.phone[+localStorage.getItem('atsLang') || 1]} :
                            </span>
                                <input type="number" value={form.phone}
                                       onChange={(ev) => handleChange('phone', ev.target.value)}/>
                            </label>
                        </div>
                        <div id="transition-modal-description sip_content" className="row" style={{marginTop: 20}}>
                            <label className="count">
                            <span>
                               {sip.note[+localStorage.getItem('atsLang') || 1]} :
                            </span>
                                <input value={form.note} onChange={(ev) => handleChange('note', ev.target.value)}/>
                            </label>
                        </div>
                        <label className="col-md-12 count" style={{margin: '20px 0'}}>
                         <span>
                               {included.mobile[+localStorage.getItem('atsLang') || 1]} :
                            </span>
                            <select
                                disabled={!(Cookies.get('viewer') && Cookies.get('original'))}
                                value={form.isMob} onChange={(ev) => handleChange('isMob', ev.target.value)}
                                style={{height: 40, border: '1px solid #ccc', outline: 'none'}}>
                                <option value="">{input.select[+localStorage.getItem('atsLang') || 1]}</option>
                                {(Cookies.get('viewer') && Cookies.get('original')) ? provider.length ? provider.map(p => (
                                    <option key={p.id} value={p.id}>{p.name}</option>
                                )) : null : <option value='42'>sipapp</option>}
                            </select>
                        </label>
                        <label className="col-md-12 count" style={{margin: '20px 0'}}>
                        <span>
                            {short.short[+localStorage.getItem('atsLang') || 1]} :
                            <input type="checkbox"
                                   style={{width: 13, height: 13, marginLeft: 10}}
                                   checked={form.isShort}
                                   onWheel={handleWheel}
                                   onChange={(ev) => handleChange('isShort', ev.target.checked)}/>
                        </span>
                            <input type="number"
                                   disabled={!form.isShort}
                                   value={form.short}
                                   onChange={(ev) => handleChange('short', ev.target.value)}
                            />
                        </label>
                        <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                            <button onClick={handleAdd} className="addBtnBig">
                                {sip.add_btn[+localStorage.getItem('atsLang') || 1]}
                            </button>
                        </div>
                    </form>
                </Box>
            </Fade>
        </Modal>
    );
}

export default AddInclude;
