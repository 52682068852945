import React, {useCallback, useState} from 'react';
import {Box, Fade, Modal, Typography} from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import {auto, global, menu, short, input, sip, weekDays} from "../translate";
import {useDispatch} from "react-redux";
import {ivrAdd} from "../store/actions/ivr";
import {autoCallCreateRequest, autoCallLocal} from "../store/actions/autoCall";
import AutoCall from "../pages/AutoCall";
import AutocallBlock from "./AutocallBlock";
import GroupCall from "./GroupCallBlock";
import Api from "../Api";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 850 ? 850 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    maxHeight: window.innerWidth > 565 ? '80vh' : '97vh',
    overflow: 'auto'
};

function AddAutoCall({open, setOpen}) {
    const [form, setForm] = useState({
        line: '',
        type: 'auto',
        name: '',
        time: []
    })

    const dispatch = useDispatch()

    const handleChange = useCallback((name, ev) => {

        setForm((prevState) => {
            if(name === 'type'){
                return({
                    ...prevState,
                    [name]: ev,
                    time:[]
                })
            }else{
                return({
                    ...prevState,
                    [name]: ev
                })
            }
        })
    }, [])

    const handleAdd = useCallback(async () => {
        await dispatch(autoCallCreateRequest(form))
        setOpen(false)
    }, [form])

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <div className="d-flex flex-row justify-content-between">
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {menu.autoCall[+localStorage.getItem('atsLang')  || 1 ]}
                        </Typography>
                        <span style={{cursor: 'pointer'}} onClick={() => setOpen(false)}>
                                                         <CloseIcon/>

                        </span>
                    </div>
                    <form onSubmit={handleAdd}>
                        <div className="row" style={{margin: '30px 0'}}>
                            <label style={{margin: '20px 0'}} className="col-md-6 count">
                            <span>
                               {auto.line[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                                <input type="number" value={form.line}
                                       onChange={(ev) => handleChange('line', ev.target.value)}/>
                            </label>
                            <label style={{margin: '20px 0'}} className="col-md-6 count">
                             <span>
                               {auto.type[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                                <select value={form.type} onChange={(ev) => handleChange('type', ev.target.value)}
                                        style={{width: '100%', height: 40}}>
                                    <option value="">{input.select[+localStorage.getItem('atsLang')  || 1]}</option>
                                    <option value="auto">Auto call</option>
                                    <option value="group">Group call</option>
                                </select>
                            </label>
                            <label style={{margin: '20px 0'}} className="col-md-12 count">
                            <span>
                               {auto.name[+localStorage.getItem('atsLang')  || 1 ]} :
                            </span>
                                <input value={form.name}
                                       onChange={(ev) => handleChange('name', ev.target.value)}/>
                            </label>
                        </div>
                        {form.type === 'auto'?<AutocallBlock form={form} setForm={setForm} />:<GroupCall form={form} setForm={setForm}/>}
                        <div className="d-flex justify-content-center" style={{marginTop: 20}}>
                            <button onClick={handleAdd} className="addBtnBig">
                                {sip.add_btn[+localStorage.getItem('atsLang')  || 1 ]}
                            </button>
                        </div>
                    </form>
                </Box>
            </Fade>
        </Modal>
    );
}

export default AddAutoCall;
