import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import {forward, global, input, menu, sip} from "../translate";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TableBody from "@mui/material/TableBody";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDownWideShort, faArrowUpShortWide, faPenToSquare, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {Link, useLocation, useNavigate} from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import SearchIcon from "@mui/icons-material/Search";
import {blockedDeleteLocal} from "../store/actions/blocked";
import {ipDeleteLocal, ipRequest} from "../store/actions/ip";
import AddIp from "../components/AddIp";
import Cookies from "js-cookie";
import Api from "../Api";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Utils from "../Utils";
import qs from "query-string";
const {REACT_APP_SERVER} = process.env;
let color = REACT_APP_SERVER === 'ATS'?'#0973b9':'#7ebc0a'

function Ip(props) {
    const ipData = useSelector(state => state.ip.ipData)
    const [create,setCreate] = useState(false)
    const [isSearch,setIsSearch] = useState(false)
    const dispatch = useDispatch()
    const [sort, setSort] = useState(null);
    const [sortOrder, setSortOrder] = useState(null);
    const location = useLocation()
    const navigate = useNavigate()
    const [copied, setCopied] = useState(false)
    const page = useSelector(state => state.ip.page)
    const total = useSelector(state => state.ip.total)
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const handleSort = (column) => {
        query.sort = column
        if (sort === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            query.sortBy = sortOrder === 'asc' ? 'desc' : 'asc'
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        } else {
            setSort(column);
            setSortOrder('asc');
            query.sortBy = 'asc'
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        }
    };
    const handleDelete = useCallback(async (id) => {
        try {
            let text = +localStorage.getItem('atsLang') === 1?"Are you sure you want to delete?":
                +localStorage.getItem('atsLang') === 2?"Вы уверены, что хотите судалить?":
                    "Վստա՞հ եք, որ ցանկանում եք ջնջել";

            if (confirm(text) === true) {
                await dispatch(blockedDeleteLocal(id))
                try {
                    await Api.deleteIp(id)
                    await dispatch(ipDeleteLocal(id))
                } catch (e) {

                }
            }
        }catch (e) {
            console.log(e)
        }
    },[])
    const handleChangePage = useCallback((page) => {
        query.page = page + 1
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])
    useEffect(() => {
        if (!Cookies.get('viewer')){
            navigate('/sip_number')
        }
    },[])
    const handleLimit = useCallback((page) => {
        query.limit = page
        query.page = '1'
        Cookies.set("ip",page)

        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])
    useEffect(() => {
        (async () => {
           await dispatch(ipRequest(Cookies.get("ip") ||query.limit || 10,query.page || 1,query.search || '',query.sort || '',query.sortBy || '',))
        })()
    },[location.search,])

    return (
        <>
            <Helmet>
                <title>
                    {menu.ip[+localStorage.getItem('atsLang')  || 1 ]}
                </title>
            </Helmet>
            <Wrapper>
                <div className="tableParent">
                    <Paper sx={{minWidth: 'fit-content', fontSize: 10}}>
                        <TableContainer
                        >
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow className="sip_header">
                                        <TableCell sx={{fontSize: 20,}} align="left" colSpan={2}>
                                            {menu.ip[+localStorage.getItem('atsLang')  || 1]}
                                        </TableCell>
                                        <TableCell sx={{fontSize: 15,}} align="right" colSpan={6}>
                                            <span className="d-flex justify-content-end">
                                                <label className="search">
                                                    <input
                                                        placeholder={input.search[+localStorage.getItem('atsLang')  || 1]}/>
                                                    <span className="search_cover" style={isSearch ? {top: 0} : {}}/>
                                                </label>
                                                <span style={{margin: '0 10px'}} className="add"
                                                      onClick={() => setIsSearch(!isSearch)}>
                                                <SearchIcon/>
                                            </span>
                                                <span className="add" onClick={() => setCreate(true)}>
                                                <AddCircleOutlineIcon/>
                                            </span>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className="sip_num">
                                        <TableCell
                                            align='center'
                                        >
                                        </TableCell>
                                        <TableCell
                                            onClick={() => handleSort('ip')}
                                            align='center'
                                        >
                                            {global.ip[+localStorage.getItem('atsLang')  || 1 ]}
                                            <span>{sort === 'ip' && sortOrder === 'asc' ?
                                                <FontAwesomeIcon icon={faArrowUpShortWide}/> :
                                                <FontAwesomeIcon icon={faArrowDownWideShort}/>}</span>
                                        </TableCell>
                                        <TableCell
                                            onClick={() => handleSort('ztime')}
                                            align='center'
                                        >
                                            {global.date[+localStorage.getItem('atsLang')  || 1 ]}
                                            <span>{sort === 'ztime' && sortOrder === 'asc' ?
                                                <FontAwesomeIcon icon={faArrowUpShortWide}/> :
                                                <FontAwesomeIcon icon={faArrowDownWideShort}/>}</span>
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {sip.action[+localStorage.getItem('atsLang')  || 1 ]}
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ipData.length ? ipData.map(s => (
                                        <TableRow key={s.id} hover role="checkbox" tabIndex={-1}>

                                            <TableCell align="center" >
                                                <CheckCircleIcon style={{color:color}} />
                                            </TableCell>
                                            <TableCell align="center">
                                                {s.ip}
                                            </TableCell>
                                            <TableCell align="center">
                                                {new Date(s.ztime).toLocaleString()}
                                            </TableCell>
                                            <TableCell className="action" align="center" sx={{display:"flex",justifyContent:'center'}}>
                                                <span onClick={() => handleDelete(s.id)} className="delete"><FontAwesomeIcon icon={faTrashCan}/></span>
                                            </TableCell>

                                        </TableRow>

                                    )) : <TableRow hover role="checkbox" tabIndex={-1}>

                                        <TableCell align="center" colSpan={5} sx={{fontSize: 13}}>
                                            no data

                                        </TableCell>
                                    </TableRow>
                                    }
                                    {/*    );*/}
                                    {/*})}*/}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="foot_footer d-flex align-items-center justify-content-end">
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.dataToExel([
                                global.ip[+localStorage.getItem('atsLang')  || 1 ],
                                global.date[+localStorage.getItem('atsLang')  || 1 ],
                            ], ipData)}>Exel</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => window.print()}>Print</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => Utils.dataToExel([
                                global.ip[+localStorage.getItem('atsLang')  || 1 ],
                                global.date[+localStorage.getItem('atsLang')  || 1 ],
                            ], ipData)}>CSV</Link>
                            <Link style={{margin: '0 10px'}} onClick={() => {
                                let table = document.getElementById('dataBody');
                                if (navigator.clipboard) {
                                    setCopied(true)

                                    let text = table.innerText.trim();
                                    navigator.clipboard.writeText(text).catch(function () {
                                    });
                                    setTimeout(() => {
                                        setCopied(false)
                                    }, 1000)
                                }
                            }}>COPY</Link>
                            <TablePagination
                                className="hello"
                                rowsPerPageOptions={[10, 25, 100,{ label: 'All', value: -1 }]}
                                component="div"
                                count={total || 1}
                                rowsPerPage={Cookies.get("ip") ||query.limit || 10}
                                page={query.page-1 || page - 1 || 0}
                                onPageChange={(event, newPage) => handleChangePage(newPage)}
                                onRowsPerPageChange={(ev,newPage) => handleLimit(newPage.props.value)}

                            />
                        </div>


                    </Paper>
                    {copied ? <div className="copied">Copied</div> : null}

                </div>
                {create ? <AddIp open={create} edit={false} setOpen={setCreate}/> : null}
            </Wrapper>
        </>

    );
}

export default Ip;
